import React, { useEffect, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'

import api from 'api'
import { cssClass } from 'helpers/helper'
import style from './style/TokenPage.module.scss'
import status from './img/status.png'
import TokenIcons from './TokenData/TokenIcons'
import { GetTokenUSDTPair, Tabs, CurrencyListingDeployStatus } from './TokenData/TokenData'

export default function TokenHeader({
  t,
  currencyListing,
  selectTab,
  setSelectTab,
  exchangeInstruments,
  projectImage,
  onGoToTokenTradePage,
  onGoToEditPage,
  isAuthor,
  isUpdateCurrencyLstingCommentsCount,
  setIsUpdateCurrencyLstingCommentsCount,
}) {
  const [tokenUSDTPair, setTokenUSDTPair] = useState(null)
  const [newsCount, setNewsCount] = useState(null)
  const [commentCount, setCommentsCount] = useState(null)

  const fetchNewsCount = () => {
    api
      .fetchCurrencyListingNewsCount(currencyListing?.currencyCode)
      .then((data) => {
        setNewsCount(data)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingNewsCount', error)
      })
  }
  const fetchCommentsCount = () => {
    api
      .fetchCurrencyListingCommentsCount(currencyListing?.currencyCode)
      .then((data) => {
        setCommentsCount(data)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingNewsCount', error)
      })
  }

  const formatedTokenDateCreated = () => {
    if (!currencyListing?.dateCreated) return '---'

    const dateCreated = new Date(currencyListing.dateCreated)
    let month = dateCreated.toLocaleString('default', { month: 'long' })
    month = month.charAt(0).toUpperCase() + month.slice(1)
    const year = dateCreated.toLocaleString('default', { year: 'numeric' })
    return `${month}, ${year}`
  }

  useEffect(() => {
    if (!exchangeInstruments) return null
    const tokenUSDTPair = GetTokenUSDTPair(exchangeInstruments)
    setTokenUSDTPair(tokenUSDTPair)
  }, [exchangeInstruments])

  useEffect(() => {
    if (!isUpdateCurrencyLstingCommentsCount) return
    fetchCommentsCount()
    setIsUpdateCurrencyLstingCommentsCount(false)
  }, [isUpdateCurrencyLstingCommentsCount])

  useEffect(() => {
    if (!currencyListing?.currencyCode) return
    const fetchData = async () => {
      try {
        await Promise.all([fetchNewsCount(), fetchCommentsCount()])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [currencyListing?.currencyCode])

  const tokenUrl = `https://tonviewer.com/${currencyListing?.addressInBlockchain}`
  const isDeployed = currencyListing != null && currencyListing.addressInBlockchain != null
  const isDeployInProcess =
    currencyListing != null &&
    (currencyListing.deployStatus === CurrencyListingDeployStatus.AwaitsPayment ||
      currencyListing.deployStatus === CurrencyListingDeployStatus.AwaitsDeployment)

  return (
    <div className={cssClass(style.container)}>
      <div className={cssClass(style.project__data, style.data)}>
        <div className={cssClass(style.data__list)}>
          <div className={cssClass(style.data__item, style.data__item_secondary)}>
            <div className={cssClass(style.data__media_img_full_size)}>
              <img src={projectImage || null} />
            </div>
            <div className={cssClass(style.data__header)}>
              <div className={cssClass(style.data__status, style.status)}>
                {!isDeployInProcess && <img src={status} />}
                {isDeployed ? (
                  <span className={cssClass(style.status__text)}>
                    {t('TokenContract')}: &nbsp;
                    <a style={{ color: 'blue' }} href={tokenUrl}>
                      {currencyListing.addressInBlockchain.slice(0, 12)}...
                    </a>
                  </span>
                ) : isDeployInProcess ? (
                  <>
                    <TailSpin width='40' color='#2196f3' wrapperStyle={{}} />
                    <span className={cssClass(style.status__text)}>{t('TokenDeployInProcess')}</span>
                  </>
                ) : (
                  <span className={cssClass(style.status__text)}>{t('TokenVerified')}</span>
                )}
              </div>
            </div>
            <div className={cssClass(style.data__main)}>
              <span className={cssClass(style.data__subtitle)}>{t('TokenProjectName')}:</span>
              <span className={cssClass(style.data__title)}>{currencyListing?.tokenName}</span>
            </div>
            <div className={cssClass(style.data__footer)}>
              <button className={cssClass(style.btn)} onClick={onGoToTokenTradePage}>
                <span className={cssClass(style.btn__text)}>{t('TokenBuyToken')}</span>
                <TokenIcons name={'arrowTop'} />
              </button>
              {isAuthor && (
                <button className={cssClass(style.data__btn)} onClick={onGoToEditPage}>
                  <TokenIcons name={'editIco'} />
                </button>
              )}
            </div>
          </div>
          <div className={cssClass(style.data__item)}>
            <div className={cssClass(style.data__main)}>
              <span className={cssClass(style.data__subtitle)}>{t('TokenToken')}:</span>
              <span className={cssClass(style.data__title)}>{currencyListing?.currencyCode}</span>
            </div>
          </div>
          <div className={cssClass(style.data__item)}>
            <div className={cssClass(style.data__main)}>
              <span className={cssClass(style.data__subtitle)}>{t('TokenTokenPrice')}:</span>
              <span className={cssClass(style.data__title)}>
                ${parseFloat(tokenUSDTPair?.price?.toFixed(8)) || '---'}
              </span>
            </div>
          </div>
          <div className={cssClass(style.data__item)}>
            <div className={cssClass(style.data__main)}>
              <span className={cssClass(style.data__subtitle)}>{t('TokenTokenDateCreated')}:</span>
              <span className={cssClass(style.data__title)}>{formatedTokenDateCreated()}</span>
            </div>
          </div>
          <div className={cssClass(style.data__item)}>
            <div className={cssClass(style.data__main)}>
              <span className={cssClass(style.data__subtitle)}>{t('TokenChangePrice')}:</span>
              <span className={cssClass(style.data__title, style.data__title_secondary)}>
                {tokenUSDTPair?.priceChangePercentage24h?.toFixed(2)}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={cssClass(style.project__tags, style.tags)}>
        <ul className={cssClass(style.tags__list)}>
          <a
            className={cssClass(style.tags__item)}
            href={currencyListing?.officialLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            <TokenIcons name={'tagsIco01'} />
            <span className={cssClass(style.tags__text)}>{t('TokenOfficalSite')}</span>
          </a>
          {currencyListing?.whitePaperLink && (
            <a
              className={cssClass(style.tags__item)}
              href={currencyListing?.whitePaperLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <TokenIcons name={'tagsIco02'} />
              <span className={cssClass(style.tags__text)}>{t('TokenWhitePaper')}</span>
            </a>
          )}
        </ul>
      </div>
      <div className={cssClass(style.project__tabs, style.tabs)}>
        <div className={cssClass(style.tabs__header)}>
          <ul className={cssClass(style.tabs__list)}>
            <li
              className={cssClass(
                style.tabs__item,
                selectTab === Tabs.BasicInformation ? cssClass(style.tabs__item_active) : null,
              )}
              onClick={() => {
                setSelectTab(Tabs.BasicInformation)
              }}
            >
              <span className={cssClass(style.tabs__text)}>{t('TokenBaseInformation')}</span>
            </li>
            <li
              className={cssClass(
                style.tabs__item,
                selectTab === Tabs.NewsArticles ? cssClass(style.tabs__item_active) : null,
              )}
              onClick={() => {
                setSelectTab(Tabs.NewsArticles)
              }}
            >
              <span className={cssClass(style.tabs__text)}>{t('TokenUpdates')}</span>
              <span className={cssClass(style.tabs__count)}>{newsCount ?? ''}</span>
            </li>
            <li
              className={cssClass(
                style.tabs__item,
                selectTab === Tabs.Comments ? cssClass(style.tabs__item_active) : null,
              )}
              onClick={() => {
                setSelectTab(Tabs.Comments)
              }}
            >
              <span className={cssClass(style.tabs__text)}>{t('TokenComments')}</span>
              <span className={cssClass(style.tabs__count)}>{commentCount ?? ''}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
