import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'auth/auth'
import authApi from 'auth/api'
import Main from 'pages/common/components/Main/Main'
import { NotificationBig } from 'pages/common/components/Notifications/Notifications'
import Modal from '../../components/layout/Modal/Modal'
import Input from '../../components/form/Input/Input'
import Checkbox from '../../components/form/Checkbox/Checkbox'
import Button from '../../components/form/Button/Button'
import ForgotPWModal from './ForgotPWModal/ForgotPWModal'
import SuccessPWModal from './ForgotPWModal/SuccessPWModal'
import { NavLink } from 'react-router-dom'
import i18n from 'i18next'
export default function RegisterPage() {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const [forgotPWModal, setForgotPWModal] = useState(false)
  const [successPWModal, setSuccessPWModal] = useState(false)
  const [registered, setRegistered] = useState(false)
  const [isEmailNotificationVisible, setIsEmailNotificationVisible] = useState(false)
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [registerPasswordRepeat, setRegisterPasswordRepeat] = useState('')
  const auth = useAuth()
  const signIn = (email, password) => {
    auth.signin(email, password, () => {
      // go back to the page we were on before signing in
      const redirectTo = location.state?.from?.pathname || '/'
      console.log('redirectTo', redirectTo)
      history.push(redirectTo)
    })
  }
  const register = () => {
    if (registerPassword !== registerPasswordRepeat) {
      alert('Passwords do not match')
      return
    }
    const inviteCode = localStorage.getItem('inviteCode') || null
    
    authApi.register(registerEmail, registerPassword, i18n.language, inviteCode).then(() => {
      setIsEmailNotificationVisible(true)
      setLoginEmail(registerEmail)
      setLoginPassword(registerPassword)
      setRegisterEmail('')
      setRegisterPassword('')
      setRegisterPasswordRepeat('')
    })
  }
  const [modalForgot, setModalForgot] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [view, setView] = useState(true)
  return (
    <div className='layout'>
      <Main>
        <Modal opened={modalForgot} blockStyle={'modal640'} modalCallback={() => setModalForgot(false)}>
          <ForgotPWModal setOpened={setForgotPWModal} />
        </Modal>
        <Modal opened={modalSuccess} blockStyle={'modal640'} modalCallback={() => setModalSuccess(false)}>
          <SuccessPWModal setOpened={setSuccessPWModal} />
        </Modal>
        <NotificationBig isVisible={isEmailNotificationVisible} setIsVisible={setIsEmailNotificationVisible} />
        <div className='layout__section section p-0'>
          <div className='section__main'>
            <div className='container container_secondary'>
              <div className='layout__authorization authorization'>
                <div className='row'>
                  <div className='col col_6 col_mob-12'>
                    {/* Блок авторизации */}
                    <div
                      className={`authorization__layout authorization__layout_login ${
                        view ? 'authorization__layout_active' : ''
                      }`}
                    >
                      <div className='authorization__header'>
                        <div className='wysiwyg'>
                          <h2>{t('Enter')}</h2>
                        </div>
                      </div>
                      <div className='authorization__main'>
                        <div className='form-item'>
                          <div className='form-item__header'>
                            <span className='form-item__title'>{t('Email')}</span>
                          </div>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <Input
                                type='text'
                                value={loginEmail}
                                inputCallback={(e) => setLoginEmail(e)}
                                addClasses={['w-100']}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-item'>
                          <div className='form-item__header'>
                            <span className='form-item__title'>{t('Password')}</span>
                          </div>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <Input
                                value={loginPassword}
                                type='password'
                                inputCallback={(e) => setLoginPassword(e)}
                                addClasses={['w-100']}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='authorization__footer'>
                        <div className='authorization__group'>
                          <div className='authorization__forgot'>
                            <span onClick={() => setModalForgot(true)}>{t('ForgotPassword')}</span>
                          </div>
                        </div>
                        <div className='authorization__group'>
                          <Button
                            buttonCallback={() => signIn(loginEmail, loginPassword)}
                            addClasses={['btn w-100']}
                            text={t('Enter')}
                          />
                        </div>
                        <div className='authorization__group'>
                          <div className='authorization__change'>
                            <span onClick={() => setView(!view)}>{t('Register')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col_6 col_mob-12'>
                    {/* Блок регистрации */}
                    <div
                      className={`authorization__layout authorization__layout_registration ${
                        !view ? 'authorization__layout_active' : ''
                      }`}
                    >
                      <div className='authorization__header'>
                        <div className='wysiwyg'>
                          <h2>{t('Registration')}</h2>
                        </div>
                      </div>
                      <div className='authorization__main'>
                        <div className='form-item'>
                          <div className='form-item__header'>
                            <span className='form-item__title'>{t('Email')}</span>
                          </div>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <Input
                                type='text'
                                value={registerEmail}
                                inputCallback={(e) => setRegisterEmail(e)}
                                addClasses={['w-100']}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-item'>
                          <div className='form-item__header'>
                            <span className='form-item__title'>{t('Password')}</span>
                          </div>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <Input
                                value={registerPassword}
                                type='password'
                                inputCallback={(e) => setRegisterPassword(e)}
                                addClasses={['w-100']}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-item'>
                          <div className='form-item__header'>
                            <span className='form-item__title'>{t('ConfirmPassword')}</span>
                          </div>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <Input
                                value={registerPasswordRepeat}
                                type='password'
                                inputCallback={(e) => setRegisterPasswordRepeat(e)}
                                addClasses={['w-100']}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-item'>
                          <div className='form-item__main'>
                            <div className='form-item__field'>
                              <div className='checkbox'>
                                <label className='checkbox__label'>
                                  <input
                                    type='checkbox'
                                    className='checkbox__input'
                                    id='checkbox__id'
                                    name='checkbox__name'
                                    onChange={() => console.log(123)}
                                  />
                                  <div className='checkbox__media'>
                                    <div className='checkbox__ico checkbox__ico_before'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 20 20'
                                        fill='none'
                                      >
                                        <rect
                                          x='1'
                                          y='1'
                                          width='18'
                                          height='18'
                                          rx='9'
                                          stroke='#A717FF'
                                          strokeWidth='2'
                                        />
                                      </svg>
                                    </div>
                                    <div className='checkbox__ico checkbox__ico_after'>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        viewBox='0 0 20 20'
                                        fill='none'
                                      >
                                        <rect
                                          x='1'
                                          y='1'
                                          width='18'
                                          height='18'
                                          rx='9'
                                          stroke='#A717FF'
                                          strokeWidth='2'
                                        />
                                        <path d='M5 11L9 15L15 5' stroke='#A717FF' strokeWidth='2' />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className='checkbox__main'>
                                    <span className='checkbox__text'>
                                      I have read and agree to the Customer{' '}
                                      <NavLink to={`/${i18n.language}/user-agreement`}>Agreement</NavLink> and{' '}
                                      <NavLink to={`/${i18n.language}/privacy-policy`}>Privacy Policy</NavLink>
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='authorization__footer'>
                        <div className='authorization__group'>
                          <Button buttonCallback={() => register()} addClasses={['btn w-100']} text={t('Register')} />
                        </div>
                        <div className='authorization__group'>
                          <div className='authorization__change'>
                            <span onClick={() => setView(!view)}>{t('AlreadyHaveAnAccount')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </div>
  )
}
