import React, { useState, useEffect, useRef } from 'react'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import api from 'api'
import store from '../store'
import styles from './Chart.module.scss'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'
import i18n from 'i18next'

const BarRanges = {
  Year: 1,
  Month: 2,
  Day: 3,
  Hour4: 4,
  Hour: 5,
  Minutes30: 6,
  Minutes15: 7,
  Minutes5: 8,
  Minutes3: 9,
  Minute: 10,
}

const RESOLUTIONS = {
  '12M': { resolution: '12M', barRange: BarRanges.Year },
  '1M': { resolution: '1M', barRange: BarRanges.Month },
  '1D': { resolution: '1D', barRange: BarRanges.Day },
  '4h': { resolution: '240', barRange: BarRanges.Hour4 },
  '1h': { resolution: '60', barRange: BarRanges.Hour },
  '30m': { resolution: '30', barRange: BarRanges.Minutes30 },
  '15m': { resolution: '15', barRange: BarRanges.Minutes15 },
  '5m': { resolution: '5', barRange: BarRanges.Minutes5 },
  '3m': { resolution: '3', barRange: BarRanges.Minutes3 },
  '1m': { resolution: '1', barRange: BarRanges.Minute },
}

const ChartTradingView = observer(() => {
  const [chartTime, setChartTime] = useState(new Date())
  const [tvWidget, setTvWidget] = useState(null)
  const containerId = useRef(`tv_${Math.random().toString(36).substring(7)}`)

  const [latestBarsWithTF, setLatestBarsWithTF] = useState([])
  const latestBarsRef = useRef(latestBarsWithTF)
  const [currentChartResolution, setCurrentChartResolution] = useState(null)
  const currentResolutionRef = useRef(currentChartResolution)
  const subscribeBarsCallbackRef = useRef(null)
  const prevSubscribeHubNameRef = useRef(null)

  useEffect(() => {
    latestBarsRef.current = latestBarsWithTF
  }, [latestBarsWithTF])

  useEffect(() => {
    currentResolutionRef.current = currentChartResolution
  }, [currentChartResolution])

  const updateLatestBars = (newBar) => {
    if (!newBar || !newBar.resolution) {
      console.warn('Invalid bar data:', newBar)
      return
    }
    const index = latestBarsWithTF?.findIndex((bar) => bar.resolution === newBar.resolution)

    if (index !== -1) {
      const updatedBars = [...latestBarsWithTF]
      updatedBars[index] = newBar
      setLatestBarsWithTF(updatedBars)
    } else {
      setLatestBarsWithTF([...latestBarsWithTF, newBar])
    }
  }

  const createDatafeed = () => ({
    onReady: (cb) =>
      setTimeout(
        () =>
          cb({
            supported_resolutions: ['1', '5', '30', '60', '240', '1D', '1M', '12M'],
            has_daily: true,
          }),
        0,
      ),

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
      onResultReadyCallback([])
    },

    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
      const symbolStub = {
        name: symbolName,
        description: '',
        type: 'crypto',
        session: '24x7',
        timezone: 'Etc/UTC',
        ticker: symbolName,
        minmov: 1,
        pricescale: 100000000,
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        supported_resolutions: ['1', '5', '30', '60', '240', '1D', '1M', '12M'],
        volume_precision: 8,
        data_status: 'streaming',
      }
      setTimeout(() => onSymbolResolvedCallback(symbolStub), 0)
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      try {
        const { from, to, firstDataRequest, countBack } = periodParams

        setCurrentChartResolution(resolution)
        const currentResolution =
          Object.values(RESOLUTIONS).find((r) => r.resolution === currentResolutionRef.current)?.barRange ||
          BarRanges.Hour

        const fromDate = new Date(from * 1000)
        const toDate = new Date(to * 1000)

        const expectedBars = countBack || Math.floor((to - from) / (parseInt(resolution) * 60))
        // console.log('Expected bars:', expectedBars, 'Period:', { from: fromDate, to: toDate })

        if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
          console.error('Invalid date range:', { from, to })
          onHistoryCallback([], { noData: true })
          return
        }

        const data = await api.fetchBars(currentResolution, store.symbol, fromDate, toDate)

        // Add check for dates before 2023
        const startOfDate = new Date(2023, 3).getTime() / 1000
        if (data.length === 0 && from < startOfDate) {
          onHistoryCallback([], { noData: true })
          return
        }

        let bars = data.length ? data.map((el) => convertBarToChartObj(el)) : []

        if (bars.length < expectedBars) {
          // console.log('Adding empty bars. Current:', bars.length, 'Expected:', expectedBars)
          bars = await addEmptyBars(symbolInfo.name, bars.slice(0), resolution, from, to, expectedBars)
        }
        if (firstDataRequest && bars.length > 0) {
          updateLatestBars({
            bar: bars[bars.length - 1],
            resolution: resolution,
          })
          // console.log('lastInsertedChartBar', new Date(bars[bars.length - 1]?.time))
        }
        onHistoryCallback(bars, { noData: !bars.length })
      } catch (error) {
        console.error('Error fetching bars:', error)
        onHistoryCallback([], { noData: true })
        onErrorCallback(error)
      }
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
      setCurrentChartResolution(resolution)
      const currentResolution =
        Object.values(RESOLUTIONS).find((r) => r.resolution === resolution)?.barRange || BarRanges.Hour
      const newSubscribeHubName = `chartUpdate_${store.symbol}_${currentResolution}`

      console.log('subscribeHubName prev:', prevSubscribeHubNameRef.current, 'new:', newSubscribeHubName)
      if (prevSubscribeHubNameRef.current === newSubscribeHubName) {
        console.log('Skipping duplicate subscription for:', newSubscribeHubName)
        return
      }

      if (store.signalRConn && prevSubscribeHubNameRef.current) {
        store.signalRConn.off('chartUpdate')
      }

      setCurrentChartResolution(resolution)
      subscribeBarsCallbackRef.current = onRealtimeCallback

      if (store.signalRConn) {
        store.signalRConn
          .invoke('ChartSubscribe', {
            GroupName: newSubscribeHubName,
            PrevGroupName: prevSubscribeHubNameRef.current,
          })
          .catch((err) => console.error('ChartSubscribe Error:', err))

        prevSubscribeHubNameRef.current = newSubscribeHubName

        store.signalRConn.on('chartUpdate', (bar) => {
          if (!bar || !subscribeBarsCallbackRef.current) return
          console.log('new chartUpdate', bar)

          const newBar = convertBarToChartObj(bar)
          const lastChartBar = latestBarsRef.current.find((bar) => bar.resolution === currentResolutionRef.current)

          if (!lastChartBar) return

          if (newBar.time < lastChartBar.bar.time) {
            console.log('Skipping older bar:', new Date(newBar.time), 'last bar time', new Date(lastChartBar.bar.time))
            return
          }

          subscribeBarsCallbackRef.current(newBar)
          updateLatestBars({
            bar: newBar,
            resolution: resolution,
          })
          console.log('new chartUpdate', bar)
        })
      }
    },

    unsubscribeBars: (subscriberUID) => {
      console.log('datafeed unsubscribeBars()', subscriberUID)
    },
  })

  const setNewBar = () => {
    if (subscribeBarsCallbackRef.current) {
      const lastChartBar = latestBarsRef.current.find((bar) => bar.resolution === currentResolutionRef.current)
      if (!lastChartBar) return

      const barDuration = convertTimeResolutionToMs(currentResolutionRef.current)
      const newBar = makeBarNoChanges(lastChartBar.bar.close, lastChartBar.bar.time)
      newBar.time = calculateRoundedTime(newBar.time, currentResolutionRef.current) + barDuration
      subscribeBarsCallbackRef.current(newBar)
      console.log('set new bar Time', new Date(newBar.time), 'TF', currentResolutionRef.current)
      updateLatestBars({
        bar: newBar,
        resolution: currentResolutionRef.current,
      })
    }
  }

  useEffect(() => {
    let timerId = setInterval(() => {
      setChartTime(new Date())
    }, 10 * 1000)
    return () => clearInterval(timerId)
  }, [])

  useEffect(() => {
    if (!latestBarsRef.current?.length || !currentResolutionRef.current) return

    const lastChartBar = latestBarsRef.current.find((bar) => bar.resolution === currentResolutionRef.current)
    if (!lastChartBar) return

    const currentTime = Date.now()
    const lastBarTimeRounded = calculateRoundedTime(lastChartBar.bar.time, currentResolutionRef.current)
    const barDuration = convertTimeResolutionToMs(currentResolutionRef.current)
    let nextTime = calculateRoundedTime(lastBarTimeRounded, currentResolutionRef.current) + barDuration
    let timeUntilNextUpdate = nextTime - currentTime

    // console.log('new update min', timeUntilNextUpdate / 1000 / 60, 'new update sec', timeUntilNextUpdate / 1000)

    let timerId = null

    const scheduleNextBar = () => {
      if (timeUntilNextUpdate > 0) {
        timerId = setTimeout(() => {
          setNewBar()
        }, timeUntilNextUpdate)
      } else {
        setNewBar()
      }
    }

    scheduleNextBar()

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }, [latestBarsWithTF, currentChartResolution])

  useEffect(() => {
    if (!store.symbol) return
    let isSubscribed = true

    const initWidget = () => {
      const container = document.getElementById(containerId.current)
      if (!container || !isSubscribed) return

      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const currentOffsetMinutes = new Date().getTimezoneOffset()
      const offsetHours = Math.floor(Math.abs(currentOffsetMinutes) / 60)
      const offsetMinutes = Math.abs(currentOffsetMinutes) % 60
      const sign = currentOffsetMinutes >= 0 ? '+' : '-'
      const timezone = `Etc/GMT${sign}${offsetHours}:${String(offsetMinutes).padStart(2, '0')}`

      const widgetOptions = {
        symbol: store.symbol,
        datafeed: createDatafeed(),
        interval: '60',
        container: containerId.current,
        library_path: '/charting_library/',
        locale: `${i18n.language}`,
        width: '100%',
        height: '100%',
        enabled_features: ['hide_resolution_in_legend'],
        disabled_features: [
          'use_localstorage_for_settings',
          'header_settings_adapter',
          'symbol_search',
          'header_symbol_search',
          'symbol_info',
          'header_chart_type',
          'display_market_status',
          'header_compare',
          'compare_symbol',
          'header_undo_redo',
          'disable_resolution_rebuild',
          'show_interval_dialog_on_key_press',
        ],
        overrides: {
          'paneProperties.topMargin': 15,
          'paneProperties.bottomMargin': 40,
        },
        time_frames: [],
        loading_screen: { backgroundColor: '#ffffff' },
        timezone: userTimeZone,
        custom_timezones: [
          {
            id: userTimeZone,
            alias: timezone,
            title: userTimeZone,
          },
        ],
      }

      const tvWidgetInstance = new window.TradingView.widget(widgetOptions)

      tvWidgetInstance.onChartReady(() => {
        tvWidgetInstance._ready = true
        setTvWidget(tvWidgetInstance)

        // add interval change listener
        tvWidgetInstance
          .chart()
          .onIntervalChanged()
          .subscribe(null, (resolution) => {
            setCurrentChartResolution(resolution)
            tvWidgetInstance.activeChart().executeActionById('chartReset')
          })
      })
    }

    initWidget()

    return () => {
      isSubscribed = false
      if (tvWidget && tvWidget._ready) {
        try {
          tvWidget.remove()
          if (store.signalRConn) {
            store.signalRConn.off('chartUpdate')
          }
        } catch (error) {
          console.error('Cleanup error:', error)
        }
        setTvWidget(null)
      }
    }
  }, [store.symbol])

  return (
    <div className={styles.chart}>
      <div className={styles.firstRowWrapper}>
        <div className={styles.elementWrapper}>
          {store.symbolBase && (
            <CurrencyIcon size={30} className={styles.elementIcon} currencyCode={store.symbolBase} />
          )}
          <span>{store.symbolBase}</span>
          <img src='/images/currencies/arrows.svg' alt='Arrows' />
          <span>{store.symbolQuote}</span>
          {store.symbolQuote && (
            <CurrencyIcon size={30} className={styles.elementIcon} currencyCode={store.symbolQuote} />
          )}
        </div>
        <div className={styles.elementWrapper}>
          <span>{format(chartTime, 'dd.MM.yyyy')}</span>
          <span>{format(chartTime, 'HH:mm')}</span>
        </div>
      </div>
      <div id={containerId.current} className={styles.chartContainer} />
    </div>
  )
})

export default ChartTradingView

function convertTimeResolutionToServer(chartResolution) {
  switch (chartResolution) {
    case '1':
      return BarRanges.Minute
    case '3':
      return BarRanges.Minutes3
    case '5':
      return BarRanges.Minutes5
    case '15':
      return BarRanges.Minutes15
    case '30':
      return BarRanges.Minutes30
    case '60':
      return BarRanges.Hour
    case '240':
      return BarRanges.Hour4
    case '1D':
      return BarRanges.Day
    case '1M':
      return BarRanges.Month
    case '12M':
      return BarRanges.Year
  }
}
function convertTimeResolutionToMs(chartResolution) {
  switch (chartResolution) {
    case '1':
      return 1000 * 60
    case '3':
      return 3 * 1000 * 60
    case '5':
      return 5 * 1000 * 60
    case '15':
      return 15 * 1000 * 60
    case '30':
      return 30 * 1000 * 60
    case '60':
      return 1000 * 60 * 60
    case '240':
      return 4 * 1000 * 60 * 60
    case '1D':
      return 1000 * 60 * 60 * 24
    case '1M':
      return 1000 * 60 * 60 * 24 * 30
    case '12M':
      return 1000 * 60 * 60 * 24 * 365
  }
}
function convertDateToServer(date) {
  if (!date || typeof date !== 'number') {
    return new Date().toISOString()
  }

  const convertedDate = new Date(date)

  if (isNaN(convertedDate.getTime())) {
    console.error('Invalid date:', date)
    return new Date().toISOString()
  }

  const minDate = new Date(0)
  if (convertedDate < minDate) {
    console.warn('Date is before 1970, using min date:', date)
    return minDate.toISOString()
  }

  return convertedDate.toISOString()
}
function convertBarToChartObj(el) {
  let date = new Date(el.date)
  return {
    time: Date.parse(date) - date.getTimezoneOffset() * 60 * 1000, //TradingView requires bar time in ms
    low: el.min,
    high: el.max,
    open: el.open,
    close: el.close,
    volume: el.volume,
  }
}

async function addEmptyBars(pairName, data, resolution, from, to, expectedBars) {
  if (convertTimeResolutionToServer(resolution) === BarRanges.Day) {
    data = clearTimePart(data, resolution)
  }

  // sort bars by time
  data?.sort((a, b) => a.time - b.time)
  let candlePeriod = convertTimeResolutionToMs(resolution)
  from *= 1000
  to *= 1000
  const dateTimeNow = new Date().getTime()

  const newData = []
  let currentTime = calculateRoundedTime(from, resolution) + candlePeriod

  // if no data - fill all period with empty bars
  if (!data || data.length === 0) {
    let lastPrice = await getLastPrice(pairName, resolution, from)
    while (currentTime <= to && currentTime < dateTimeNow) {
      newData.push(makeBarNoChanges(lastPrice, currentTime))
      currentTime += candlePeriod
    }
    return newData
  }

  // go through all time range
  while (currentTime <= to) {
    // find existing bar for current time
    const existingBar = data.find((bar) => bar.time >= currentTime && bar.time < currentTime + candlePeriod)

    if (existingBar) {
      existingBar.time = calculateRoundedTime(existingBar.time, resolution)
      newData.push(existingBar)
    } else {
      // if bar not found, create empty bar
      const lastPrice =
        newData.length > 0 ? newData[newData.length - 1].close : await getLastPrice(pairName, resolution, currentTime)
      newData.push(makeBarNoChanges(lastPrice, currentTime))
    }
    currentTime += candlePeriod
  }
  return newData
}
function makeBarNoChanges(price, time) {
  return {
    time: time,
    low: price,
    high: price,
    open: price,
    close: price,
    volume: 0,
    volumeBase: 0,
    isFake: true,
  }
}

function clearTimePart(data, resolution) {
  let candlePeriod = convertTimeResolutionToMs(resolution)
  //console.log("clearTimePart()", candlePeriod);
  for (var i = 0; i < data.length; i++) {
    data[i].time = Math.floor(data[i].time / candlePeriod) * candlePeriod
  }
  return data
}

async function getLastPrice(pair, resolution, before) {
  try {
    const serverResolution = convertTimeResolutionToServer(resolution)
    const lastBar = await api.fetchLastPrice(serverResolution, pair, before ? convertDateToServer(before) : null)

    return lastBar?.close || 0
  } catch (err) {
    console.log('getLastPrice()', err)
    return 0
  }
}

const calculateRoundedTime = (currentTime, resolution) => {
  let dateTime = new Date(currentTime)

  switch (resolution) {
    case '1':
      dateTime.setSeconds(0, 0)
      break
    case '5':
      dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 5) * 5, 0, 0)
      break
    case '15':
      dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 15) * 15, 0, 0)
      break
    case '30':
      dateTime.setMinutes(Math.floor(dateTime.getMinutes() / 30) * 30, 0, 0)
      break
    case '60':
      dateTime.setMinutes(0, 0, 0)
      break
    case '240':
      dateTime.setHours(Math.floor(dateTime.getHours() / 4) * 4, 0, 0, 0)
      break
    case '1D':
      dateTime.setHours(0, 0, 0, 0)
      break
    case '1M':
      dateTime.setDate(1)
      dateTime.setHours(0, 0, 0, 0)
      break
    case '12M':
      dateTime.setMonth(0)
      dateTime.setDate(1)
      dateTime.setHours(0, 0, 0, 0)
      break
    default:
      console.warn('Unknown resolution:', resolution)
      dateTime.setMinutes(0, 0, 0)
      break
  }

  return dateTime.getTime()
}
