import { cssClass } from 'helpers/helper'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { format } from 'date-fns'

import api from 'api'
import baseComponents from '../../../baseComponents/BaseComponents.module.scss'
import pages from '../../pages.module.scss'
import styles from './Referrals.module.scss'
import arrowLeftImg from './images/arrowLeft.svg'

const tabs = ['Information', 'Statistics', 'Referrals']

const accrualTitles = ['DateAndTime', 'AccrualType', 'Bonus']
const totalAccrualTitles = ['Number', 'GeneralBonus', 'RegistrationDate']

export default function Referral() {
  const { t } = useTranslation()

  const [tab, setTab] = useState(tabs[0])
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  // New state for affiliate data
  const [inviteCode, setInviteCode] = useState('')
  const [affiliateAccruals, setAffiliateAccruals] = useState(null)
  const [totalAccruals, setTotalAccruals] = useState(null)

  useEffect(() => {
    // Fetch affiliate invite code
    api
      .fetchAffiliateInviteCode()
      .then((res) => {
        setInviteCode(res)
      })
      .catch((err) => {
        console.error('Error fetching affiliate code:', err)
        toast.error(t('ErrorTryLater'))
      })

    // Fetch total affiliate stats for the Information tab
    api
      .fetchAffiliateTotalAccruals()
      .then((res) => {
        setTotalAccruals(res)
      })
      .catch((err) => {
        console.error('Error fetching total accruals:', err)
      })
  }, [])

  const reloadAffiliateAccruals = () => {
    if (tab !== tabs[1] && affiliateAccruals != null) return

    setIsLoading(true)
    api
      .fetchAffiliateAccruals(currentPage)
      .then((page) => {
        console.log('page', page)
        setAffiliateAccruals(page.items)
        setLastPage(page.totalPages - 1)
      })
      .catch((err) => {
        console.error('Error fetching affiliate accruals:', err)
        toast.error(t('ErrorTryLater'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    reloadAffiliateAccruals()
  }, [tab, currentPage])

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(t('CopiedToClipboard'))
      })
      .catch((err) => {
        console.error('Failed to copy:', err)
        toast.error(t('ErrorTryLater'))
      })
  }

  const inviteUrl = `${window.location.origin}/?i=${inviteCode}`
  const extendedInviteText = `${t('ReferralsCopySecond')} ${inviteUrl}`

  return (
    <>
      <section className={pages.blockWrapper}>
        <div className={cssClass(styles.section)}>
          <div className={cssClass(styles.section__header)}>
            <div className={cssClass(styles.section__title)}>{t('ReferralsTitle')}</div>
          </div>
          <div className={cssClass(styles.section__main)}>
            <div className={cssClass(styles.section__subsection)}>
              <div className={cssClass(styles.reference)}>
                <div className={cssClass(styles.reference__list)}>
                  <div className={cssClass(styles.reference__item)}>
                    <div className={cssClass(styles.reference__header)}>{t('YourLink')}</div>
                    <div className={cssClass(styles.reference__layout)}>
                      <div className={cssClass(styles.reference__main)}>
                        <textarea className={cssClass(styles.reference__field)} readOnly value={inviteUrl}>
                          {inviteUrl}
                        </textarea>
                      </div>
                      <div className={cssClass(styles.reference__footer)}>
                        <div className={cssClass(styles.reference__action)} onClick={() => copyToClipboard(inviteUrl)}>
                          <svg
                            className={cssClass(styles.reference__ico)}
                            xmlns='http://www.w3.org/2000/svg'
                            width='13'
                            height='15'
                            viewBox='0 0 13 15'
                            fill='none'
                          >
                            <path
                              d='M1.52941 15C1.10882 15 0.748648 14.853 0.448883 14.559C0.149119 14.265 -0.000508506 13.912 1.29831e-06 13.5V3H1.52941V13.5H9.94118V15H1.52941ZM4.58824 12C4.16765 12 3.80747 11.853 3.50771 11.559C3.20794 11.265 3.05831 10.912 3.05882 10.5V1.5C3.05882 1.0875 3.20871 0.734251 3.50847 0.440251C3.80824 0.146251 4.16816 -0.000498727 4.58824 1.27335e-06H11.4706C11.8912 1.27335e-06 12.2514 0.147001 12.5511 0.441001C12.8509 0.735001 13.0005 1.088 13 1.5V10.5C13 10.9125 12.8501 11.2658 12.5504 11.5598C12.2506 11.8538 11.8907 12.0005 11.4706 12H4.58824Z'
                              fill='url(#paint0_radial_3360_15121)'
                            />
                            <defs>
                              <radialGradient
                                id='paint0_radial_3360_15121'
                                cx='0'
                                cy='0'
                                r='1'
                                gradientUnits='userSpaceOnUse'
                                gradientTransform='translate(16.0115 15) rotate(-137.82) scale(26.435 20.4823)'
                              >
                                <stop stopColor='#003629' />
                                <stop offset='1' stopColor='#7F00E2' />
                              </radialGradient>
                            </defs>
                          </svg>
                          <span className={cssClass(styles.reference__text)}>{t('Copy')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={cssClass(styles.reference__item)}>
                    <div className={cssClass(styles.reference__header)}>{t('YourLinkText')}</div>
                    <div className={cssClass(styles.reference__layout)}>
                      <div className={cssClass(styles.reference__main)}>
                        <textarea
                          className={cssClass(styles.reference__field, styles.reference__field_second)}
                          readOnly
                          value={extendedInviteText}
                        >
                          {extendedInviteText}
                        </textarea>
                      </div>
                      <div className={cssClass(styles.reference__footer)}>
                        <div
                          className={cssClass(styles.reference__action)}
                          onClick={() => copyToClipboard(extendedInviteText)}
                        >
                          <svg
                            className={cssClass(styles.reference__ico)}
                            xmlns='http://www.w3.org/2000/svg'
                            width='13'
                            height='15'
                            viewBox='0 0 13 15'
                            fill='none'
                          >
                            <path
                              d='M1.52941 15C1.10882 15 0.748648 14.853 0.448883 14.559C0.149119 14.265 -0.000508506 13.912 1.29831e-06 13.5V3H1.52941V13.5H9.94118V15H1.52941ZM4.58824 12C4.16765 12 3.80747 11.853 3.50771 11.559C3.20794 11.265 3.05831 10.912 3.05882 10.5V1.5C3.05882 1.0875 3.20871 0.734251 3.50847 0.440251C3.80824 0.146251 4.16816 -0.000498727 4.58824 1.27335e-06H11.4706C11.8912 1.27335e-06 12.2514 0.147001 12.5511 0.441001C12.8509 0.735001 13.0005 1.088 13 1.5V10.5C13 10.9125 12.8501 11.2658 12.5504 11.5598C12.2506 11.8538 11.8907 12.0005 11.4706 12H4.58824Z'
                              fill='url(#paint0_radial_3360_15121)'
                            />
                            <defs>
                              <radialGradient
                                id='paint0_radial_3360_15121'
                                cx='0'
                                cy='0'
                                r='1'
                                gradientUnits='userSpaceOnUse'
                                gradientTransform='translate(16.0115 15) rotate(-137.82) scale(26.435 20.4823)'
                              >
                                <stop stopColor='#003629' />
                                <stop offset='1' stopColor='#7F00E2' />
                              </radialGradient>
                            </defs>
                          </svg>
                          <span className={cssClass(styles.reference__text)}>{t('Copy')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cssClass(styles.section__subsection)}>
              <ReferralTabs tab={tab} setTab={setTab} setCurrentPage={setCurrentPage} />

              {tab !== tabs[0] && isLoading ? (
                <div>{t('Loading')}...</div>
              ) : (
                <TabContent tab={tab} affiliateAccruals={affiliateAccruals} totalAccruals={totalAccruals} />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Only show pagination when we have rows in the active tab */}
      {tab === tabs[1] && affiliateAccruals != null && (
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} lastPage={lastPage} />
      )}
    </>
  )
}

// Tab content renderer based on active tab
const TabContent = ({ tab, affiliateAccruals, totalAccruals }) => {
  console.log('affiliateAccruals', affiliateAccruals)
  switch (tab) {
    case tabs[0]:
      return <InformationTab />
    case tabs[1]:
      return <AccrualsTab data={affiliateAccruals} />
    case tabs[2]:
      return <TotalAccrualsTab data={totalAccruals} />
    default:
      return null
  }
}

// Information tab (Tab 1)
const InformationTab = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.optionsWrapper}>
      <div className={cssClass(styles.wysiwyg)}>
        <p dangerouslySetInnerHTML={{ __html: t('ReferralsDescription') }}></p>
      </div>
    </div>
  )
}

// Affiliate accruals tab (Tab 2)
const AccrualsTab = ({ data }) => {
  const { t } = useTranslation()

  console.log('data', data)
  if (data === null) {
    return <div className={styles.noData}>{t('NoDataAvailable')}</div>
  }

  return (
    <>
      <div className={styles.optionsWrapper}></div>
      <div className={cssClass(styles.table, styles.desktopOnly)}>
        <div className={cssClass(styles.row, styles.titleRow, styles.rowTabSecondary)}>
          {accrualTitles.map((title, index) => (
            <div key={index} className={cssClass(styles.cell, styles.tableTitle)}>
              {t(title)}
            </div>
          ))}
        </div>

        {data.length > 0 && <div className={styles.line}></div>}

        {data.map((accrual, index) => (
          <div
            key={index}
            className={cssClass(styles.row, (index % 2) - 1 && styles.cellColoredBg, styles.rowTabSecondary)}
          >
            <div className={styles.cell}>{format(new Date(accrual.date), 'dd.MM.yyyy HH:mm')}</div>
            <div className={styles.cell}>{t('Deal')}</div>
            <div className={styles.cell}>
              {accrual.amount} {accrual.currencyCode}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

// Total accruals tab (Tab 3)
const TotalAccrualsTab = ({ data }) => {
  const { t } = useTranslation()

  if (data === null) {
    return <div className={styles.noData}>{t('NoDataAvailable')}</div>
  }

  return (
    <>
      <div className={styles.optionsWrapper}></div>
      <div className={cssClass(styles.table, styles.desktopOnly)}>
        <div className={cssClass(styles.row, styles.titleRow, styles.rowTabTertiary)}>
          {totalAccrualTitles.map((title, index) => (
            <div key={index} className={cssClass(styles.cell, styles.tableTitle)}>
              {t(title)}
            </div>
          ))}
        </div>

        {data.length > 0 && <div className={styles.line}></div>}

        {data.map((accrual, index) => {
          const amountsStr = Object.entries(accrual.totalAmountsByCurrency)
            .map(([currency, amount]) => ({ currency, amount })) // Convert to array of objects
            .sort((a, b) => a.currency.localeCompare(b.currency)) // Sort by currency code
            .map((item) => `${item.amount} ${item.currency}`) // Formating
            .join(', ')

          return (
            <div
              key={index}
              className={cssClass(styles.row, (index % 2) - 1 && styles.cellColoredBg, styles.rowTabTertiary)}
            >
              <div className={styles.cell}>{accrual.fromUsername}</div>
              <div className={styles.cell}>{amountsStr}</div>
              <div className={styles.cell}>{format(new Date(accrual.dateRegistered), 'dd.MM.yyyy')}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const ReferralTabs = ({ tab, setTab, setCurrentPage }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.tabWrapper}>
      {tabs.map((element, index) => {
        return (
          <div key={index}>
            <button
              className={cssClass(baseComponents.button, tab === element ? baseComponents.buttonActive : null)}
              onClick={() => {
                setTab(element)
                setCurrentPage(1)
              }}
            >
              {t(element)}
            </button>
          </div>
        )
      })}
    </div>
  )
}

const Pagination = ({ currentPage, setCurrentPage, lastPage }) => {
  const prevValid = currentPage - 1 > 0
  const nextValid = currentPage <= lastPage
  const prevStyle = prevValid ? baseComponents.buttonOutlined : baseComponents.button
  const nextStyle = nextValid ? baseComponents.buttonOutlined : baseComponents.button

  return (
    <div className={styles.paginationWrapper}>
      <button
        onClick={() => prevValid && setCurrentPage((prev) => prev - 1)}
        className={cssClass(prevStyle, styles.arrowButton)}
      >
        <img src={arrowLeftImg} alt='Arrow left' />
      </button>

      <div className={styles.paginationCenterWrapper}>
        <span className={styles.pageNumber}>{currentPage}</span>
      </div>

      <button
        onClick={() => nextValid && setCurrentPage((prev) => prev + 1)}
        className={cssClass(nextStyle, styles.arrowButton, styles.arrowRight)}
      >
        <img src={arrowLeftImg} alt='Arrow right' />
      </button>
    </div>
  )
}

const EllipsisPage = ({ currentPage, setCurrentPage }) => {
  const [value, setValue] = useState('...')
  const refInput = useRef(null)

  useEffect(() => {
    refInput.current.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        setCurrentPage(parseInt(e.target.value))
      }
    })
  })
  return (
    <span className={cssClass(styles.pageNumber, styles.ellipsisWrapper)}>
      <input
        ref={refInput}
        className={cssClass(baseComponents.input, baseComponents.buttonOutlined, styles.ellipsis)}
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onClick={() => setValue('')}
        onBlur={() => setValue('...')}
      />
    </span>
  )
}
