import React, { useEffect, useState } from 'react'
import api from 'api.js'
import { toast } from 'react-toastify'

import Modal from 'components/layout/Modal/Modal'
import TokenInput from '../../TokenData/TokenInput.jsx'
import CurrencyIcon from 'pages/common/components/CurrencyIcon'

export default function CreateInBlockchainModal({ isOpened, close, t, tokenData }) {
  const [balances, setBalances] = useState(null)
  const [isSufficientBalance, setIsSufficientBalance] = useState(false)

  const deployCost = 20
  const deployCostCurrency = 'USDT'

  const deployCurrencyListingToTON = async () => {
    try {
      await api.deployCurrencyListingToTON(tokenData.currencyCode)
      toast.success(t('TokenDeployStarted'))
      close()
    } catch (error) {
      console.error('Error deploying', error)
      toast.error(t('ErrorTryLater'))
    }
  }

  // initial load
  useEffect(() => {
    api.fetchBalance().then((data) => {
      setBalances(data.balances)

      const freeBalance = data.balances.find((_) => _.currencyCode == deployCostCurrency)?.amount ?? 0
      if (freeBalance > deployCost) {
        setIsSufficientBalance(true)
      }
    })
  }, [])

  if (tokenData.addressInBlockchain != null) {
    const url = `https://tonviewer.com/${tokenData.addressInBlockchain}`
    return (
      <Modal opened={isOpened} blockStyle={'modal640'} modalCallback={close}>
        <p>
          {t('TokenDeployedSuccessfully')}: <a style={{color: 'blue'}} href={url}>{url}</a>
        </p>
      </Modal>
    )
  }
  if (tokenData.deployStatus !== 0) {
    return (
      <Modal opened={isOpened} blockStyle={'modal640'} modalCallback={close}>
        <p>
          {t('TokenDeployInProcess')}
        </p>
      </Modal>
    )
  }

  return (
    <Modal opened={isOpened} blockStyle={'modal640'} modalCallback={close}>
      <div className='modal__main'>
        <div className='group__main layout_Tokens'>
          <div id='tokens__info' className='tokens__group group'>
            <p>
              {t('TokenDeployCostIs')}: {deployCost} {deployCostCurrency}
            </p>
            {balances !== null && !isSufficientBalance && <p style={{ color: 'red' }}>{t('InsufficientBalance')}</p>}
            <div className='form__item'>
              <div className='form__main'>
                <label className='form__field'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_primary']}
                    value={tokenData.tokenName || ''}
                    validationPattern='^ *([a-zA-Z]+ ?){1,3}$'
                    disabled={true}
                  />
                </label>
              </div>
              <div className='form__footer'>
                <span className='form__tooltip'>{t('TokenYourProjectUnabbreviatedName')}</span>
              </div>
            </div>
            <div className='form__item'>
              <div className='form__main'>
                <label className='form__field'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_primary']}
                    value={tokenData.currencyCode || ''}
                    validationPattern='^[a-zA-Z]{0,7}$'
                    disabled={true}
                  />
                </label>
              </div>
              <div className='form__footer'>
                <span className='form__tooltip'>{t('TokenCurrencySymbolAppearing')}</span>
              </div>
            </div>
            <div className='form__item'>
              <div className='form__main'>
                <label className='form__field'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_primary']}
                    value={tokenData.tokenDecimalPlaces || ''}
                    validationPattern='^[0-8]{0,1}$'
                    disabled={true}
                  />
                </label>
              </div>
              <div className='form__footer'>
                <span className='form__tooltip'>{t('TokenTheDecimalPrecisionOfYourToken')}</span>
              </div>
            </div>
            <div className='form__item'>
              <div className='form__main'>
                <label className='form__field'>
                  <TokenInput
                    addClasses={['form__input', 'form__input_primary']}
                    type='text'
                    value={tokenData.totalTokensAmount || ''}
                    disabled={true}
                  />
                </label>
              </div>
              <div className='form__footer'>
                <span className='form__tooltip'>
                  {'. '} {t('TokenNumberOfInitialTokensWill')} {tokenData.totalTokensAmount}
                </span>
              </div>
            </div>
            {t('TokenTokenLogo')}:
            <CurrencyIcon currencyCode={tokenData.currencyCode} size={100} />
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='formular'>
          <div className='row'>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button
                      className='btn btn_quartyty w-100'
                      onClick={deployCurrencyListingToTON}
                      disabled={!isSufficientBalance}
                    >
                      <span className='btn__text'>Ok</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col col_6'>
              <div className='form-item'>
                <div className='form-item__main'>
                  <div className='form-item__field'>
                    <button className='btn btn_revert w-100' onClick={close}>
                      <span className='btn__text'>{t('Close')}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
